import { APP_VERSION } from '@cashbox-env/version';

export const environment = {
  production: true,
  version: APP_VERSION,
  sentryUrl: 'https://bc49026d3b52496786bb575a76781d67@sentry.happycake.co/3',
  keycloakUrl: 'https://auth.happyapps.pro/auth',
  keycloakRealm: 'happy',
  keycloakClientId: 'cb-frontend'
};
